<template>
  <div :class="['app-wrapper', isCollapse ? 'mini-side' : '']">
    <!-- 左侧导航栏 -->
    <aside class="sidebar">
      <!-- 顶部名称及logo -->
      <div class="logo-container">
        <img
          src="../assets/jinchenglogo.png"
          alt="锦城"
          v-if="back == 'jc-shang' || back == 'jc-xia' ? true : false"
          style="border-radius: 99px"
        />
        <img
          src="../assets/huaxin.png"
          alt="华鑫"
          v-if="back == 'hx-shang' ? true : false"
          style="border-radius: 99px"
        />
        <img
          src="../assets/gy-shang.png"
          alt="国邦盛世"
          v-if="back == 'gy-shang' ? true : false"
          style="border-radius: 99px"
        />
        <img
          src="../assets/fengshilogoxiao.png"
          alt="深圳丰石"
          v-if="back == 'zq-xia' ? true : false"
          style="border-radius: 99px"
        />
        <img
          src="../assets/zyxxlogo.png"
          alt="中炎信息"
          v-if="back == 'jc-zyxx' ? true : false"
          style="border-radius: 99px"
        />
        <span style="font-size: 14px">{{ system }}</span>
      </div>
      <el-menu
        :default-active="defauitUrl"
        background-color="#303133"
        text-color="#909399"
        active-text-color="#ffffff"
        :collapse-transition="false"
        :collapse="isCollapse"
        @select="menuSelected"
        router
        unique-opened
      >
        <!-- 首页 -->
        <el-menu-item index="/index">
          <i class="el-icon-menu"></i>
          <span slot="title">首页</span>
        </el-menu-item>
        <!-- 业务管理 -->
        <el-submenu index="/branch-worker-power" v-if="$comm.computer(1)">
          <template slot="title">
            <i class="el-icon-s-home"></i>
            <span>业务管理</span>
          </template>
          <el-menu-item index="/Branch" v-if="$comm.computer(350)">
            <i class="el-icon-s-platform"></i>
            <span>架构管理</span>
          </el-menu-item>
          <el-menu-item index="/dept" v-if="$comm.computer(2)">
            <i class="el-icon-s-custom"></i>
            <span>人员管理</span>
          </el-menu-item>
          <el-menu-item index="/employeeReview" v-if="$comm.computer(260)">
            <i class="el-icon-user"></i>
            <span>员工审核</span>
          </el-menu-item>
          <el-menu-item index="/jueSe" v-if="$comm.computer(3)">
            <i class="el-icon-user-solid"></i>
            <span>角色管理</span>
          </el-menu-item>
          <el-menu-item index="/household" v-if="$comm.computer(144)">
            <i class="el-icon-s-check"></i>
            <span>公户管理</span>
          </el-menu-item>
          <el-menu-item index="/Rankmanagement" v-if="$comm.computer(261)">
            <i class="el-icon-finished"></i>
            <span>职级管理</span>
          </el-menu-item>
          <el-menu-item index="/rankscore" v-if="$comm.computer(436)">
            <i class="el-icon-tickets"></i>
            <span>职级评分</span>
          </el-menu-item>
          <el-menu-item index="/performancelist" v-if="$comm.computer(420)">
            <i class="el-icon-finished"></i>
            <span>业绩管理</span>
          </el-menu-item>
        </el-submenu>
        <!-- 用户管理 -->
        <el-submenu index="/user" v-if="$comm.computer(4)">
          <template slot="title">
            <i class="el-icon-user"></i>
            <span>用户管理</span>
          </template>
          <el-menu-item index="/MineUser" v-if="$comm.computer(5)">
            <i class="el-icon-fork-spoon"></i>
            <span>个人客户创建</span>
          </el-menu-item>
          <!-- <el-menu-item index="/Distribution" v-if="false">
            <template slot="title">
              <i class="el-icon-user-solid"></i>
              <span>员工管理</span>
            </template>
          </el-menu-item> -->
          <el-menu-item index="/BranchUser" v-if="$comm.computer(7)">
            <i class="el-icon-knife-fork"></i>
            <span>团队客户管理</span>
          </el-menu-item>
          <el-menu-item index="/Highmanagement" v-if="$comm.computer(306)">
            <i class="el-icon-guide"></i>
            <span>公海管理</span>
          </el-menu-item>
        </el-submenu>
        <!-- <el-submenu index="/3" v-if="false">
          <template slot="title">
            <i class="el-icon-s-check"></i>
            <span>业绩管理</span>
          </template>
          <el-menu-item index="/performance" v-if="$comm.computer(10)">
            <i class="el-icon-document"></i>
            <span slot="title">绩效管理</span>
          </el-menu-item>
        </el-submenu> -->
        <!-- 订单管理 -->
        <el-submenu index="/order" v-if="$comm.computer(11)">
          <template slot="title">
            <i class="el-icon-wallet"></i>
            <span>订单管理</span>
          </template>
          <el-submenu index="/orderrec">
            <template slot="title">
              <i class="el-icon-wallet"></i>
              <span>订单审核</span>
            </template>
            <el-menu-item index="/OrderAuditOne" v-if="$comm.computer(12)">
              <i class="el-icon-check"></i>
              <span>订单初审</span>
            </el-menu-item>
            <el-menu-item
              index="/AuditPay"
              v-if="$comm.computer(367)"
              v-show="back == 'jc-zyxx'"
            >
              <i class="el-icon-coin"></i>
              <span slot="title">财务复审</span>
            </el-menu-item>
            <el-menu-item index="/OrderAuditTwo" v-if="$comm.computer(13)">
              <i class="el-icon-finished"></i>
              <span slot="title">常规复审</span>
            </el-menu-item>
            <el-menu-item
              index="/AuditPay"
              v-if="$comm.computer(367)"
              v-show="back != 'jc-zyxx'"
            >
              <i class="el-icon-coin"></i>
              <span slot="title">财务复审</span>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="/orderone">
            <template slot="title">
              <i class="el-icon-wallet"></i>
              <span>个人订单管理</span>
            </template>
            <el-menu-item index="/Individualorder" v-if="$comm.computer(83)">
              <i class="el-icon-s-custom"></i>
              <span slot="title">个人订单</span>
            </el-menu-item>
            <el-menu-item index="/OrderIndividual" v-if="$comm.computer(208)">
              <i class="el-icon-edit-outline"></i>
              <span slot="title">待审核订单(个人)</span>
            </el-menu-item>
            <el-menu-item index="/PersonalOrder" v-if="$comm.computer(134)">
              <i class="el-icon-edit"></i>
              <span slot="title">待修改订单(个人)</span>
            </el-menu-item>
            <el-menu-item index="/drafts" v-if="$comm.computer(128)">
              <i class="el-icon-document"></i>
              <span slot="title">草稿箱</span>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="/orderall">
            <template slot="title">
              <i class="el-icon-wallet"></i>
              <span>团队订单管理</span>
            </template>
            <el-menu-item index="/Orderlist" v-if="$comm.computer(14)">
              <i class="el-icon-s-home"></i>
              <span slot="title">团队订单</span>
            </el-menu-item>
            <el-menu-item index="/OrderReviewedTeam" v-if="$comm.computer(209)">
              <i class="el-icon-edit-outline"></i>
              <span slot="title">待审核订单(团队)</span>
            </el-menu-item>
            <el-menu-item index="/Rejectorder" v-if="$comm.computer(85)">
              <i class="el-icon-edit-outline"></i>
              <span slot="title">待修改订单(团队)</span>
            </el-menu-item>
          </el-submenu>
        </el-submenu>
        <!-- 财务管理 -->
        <el-submenu index="/financial" v-if="$comm.computer(124)">
          <template slot="title">
            <i class="el-icon-c-scale-to-original"></i>
            <span>财务管理</span>
          </template>
          <el-menu-item index="/FinancialView" v-if="$comm.computer(356)">
            <i class="el-icon-notebook-2"></i>
            <span slot="title">财务申请</span>
          </el-menu-item>
          <el-menu-item index="/FinancialAuditView" v-if="$comm.computer(357)">
            <i class="el-icon-coordinate"></i>
            <span slot="title">财务审核</span>
          </el-menu-item>
          <el-menu-item index="/FinancialWaitPay" v-if="$comm.computer(87)">
            <i class="el-icon-sort-down"></i>
            <span slot="title">待付款</span>
          </el-menu-item>
          <el-menu-item index="/FinancialPaying" v-if="$comm.computer(88)">
            <i class="el-icon-sort-up"></i>
            <span slot="title">付款中</span>
          </el-menu-item>
          <el-menu-item index="/FinancialPaid" v-if="$comm.computer(89)">
            <i class="el-icon-sort"></i>
            <span slot="title">已付款</span>
          </el-menu-item>
          <el-menu-item index="/entry" v-if="$comm.computer(256)">
            <i class="el-icon-sell"></i>
            <span slot="title">财务入账</span>
          </el-menu-item>
        </el-submenu>
        <!-- 短信管理 -->
        <el-submenu index="/5" v-if="$comm.computer(15)">
          <template slot="title">
            <i class="el-icon-message-solid"></i>
            <span>短信管理</span>
          </template>
          <el-menu-item index="/smsTemplate" v-if="$comm.computer(16)">
            <i class="el-icon-s-comment"></i>
            <span slot="title">短信模板管理</span>
          </el-menu-item>
          <el-menu-item index="/markesmsTemplate" v-if="$comm.computer(16)">
            <i class="el-icon-s-comment"></i>
            <span slot="title">营销短信模板管理</span>
          </el-menu-item>
          <el-menu-item index="/UserTags" v-if="$comm.computer(17)">
            <i class="el-icon-price-tag"></i>
            <span slot="title">用户标签管理</span>
          </el-menu-item>
          <el-menu-item index="/SMSrecord" v-if="$comm.computer(220)">
            <i class="el-icon-chat-dot-square"></i>
            <span slot="title">短信记录</span>
          </el-menu-item>
        </el-submenu>
        <!-- 产品管理 -->
        <el-submenu index="/100" v-if="$comm.computer(19)">
          <template slot="title">
            <i class="el-icon-shopping-bag-1"></i>
            <span>产品管理</span>
          </template>
          <el-menu-item index="/company" v-if="$comm.computer(404)">
            <i class="el-icon-office-building"></i>
            <span slot="title">机构管理</span>
          </el-menu-item>
          <el-menu-item index="/welfare" v-if="$comm.computer(393)">
            <i class="el-icon-goods"></i>
            <span slot="title">公益项目</span>
          </el-menu-item>
          <el-submenu index="/100-1" v-if="$comm.computer(20)">
            <template slot="title">
              <i class="el-icon-box"></i>
              <span>理财管理</span>
            </template>
            <el-menu-item index="/classification" v-if="$comm.computer(20)">
              <i class="el-icon-goods"></i
              ><!-- 济南丰晟专用 -->
              <!-- <span slot="title">资产配置</span> -->
              <span slot="title">理财产品</span>
            </el-menu-item>
            <el-menu-item index="/ProductCategory" v-if="$comm.computer(210)">
              <i class="el-icon-box"></i>
              <span slot="title">理财分类</span>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="/101-1" v-if="$comm.computer(401)">
            <template slot="title">
              <i class="el-icon-box"></i>
              <span>保险管理</span>
            </template>
            <el-menu-item index="/insure" v-if="$comm.computer(401)">
              <i class="el-icon-goods"></i>
              <span slot="title">保险产品</span>
            </el-menu-item>
            <el-menu-item index="/insureclass" v-if="$comm.computer(402)">
              <i class="el-icon-box"></i>
              <span slot="title">保险分类</span>
            </el-menu-item>
          </el-submenu>
        </el-submenu>
        <!-- 电子签约 -->
        <el-submenu index="/1085410" v-if="$comm.computer(234)">
          <!-- <el-submenu index="/1085410" v-if="false"> -->
          <template slot="title">
            <i class="iconfont icon-hetongguanli-"></i>
            <span>电子签约</span>
          </template>
          <el-menu-item index="/contractList" v-if="$comm.computer(235)">
            <i class="iconfont icon-hetongliebiao"></i>
            <span slot="title">模版管理</span>
          </el-menu-item>
          <el-menu-item index="/hetongList" v-if="$comm.computer(381)">
            <i class="iconfont icon-hetongliebiao"></i>
            <span slot="title">合同管理</span>
          </el-menu-item>
          <el-menu-item index="/Enterprise" v-if="$comm.computer(266)">
            <i class="el-icon-user"></i>
            <span slot="title">印章管理</span>
          </el-menu-item>
        </el-submenu>
        <!-- 充值管理 -->
        <el-submenu index="/1085411" v-if="$comm.computer(382)">
          <!-- <el-submenu index="/1085410" v-if="false"> -->
          <template slot="title">
            <i class="el-icon-s-finance"></i>
            <span>充值管理</span>
          </template>
          <el-menu-item index="/enterpay" v-if="$comm.computer(383)">
            <i class="el-icon-wallet"></i>
            <span slot="title">电签充值管理</span>
          </el-menu-item>
          <el-menu-item index="/SMSRecharge" v-if="$comm.computer(219)">
            <i class="el-icon-wallet"></i>
            <span slot="title">短信充值管理</span>
          </el-menu-item>
          <el-menu-item index="/rechargeRecord" v-if="$comm.computer(249)">
            <i class="el-icon-postcard"></i>
            <span slot="title">充值记录</span>
          </el-menu-item>
        </el-submenu>
        <!-- 文章管理 -->
        <el-submenu index="/1085450" v-if="$comm.computer(187)">
          <template slot="title">
            <i class="iconfont icon-wenzhangguanli"></i>
            <span>文章管理</span>
          </template>
          <el-menu-item index="/article" v-if="$comm.computer(189)">
            <i class="iconfont icon-wenzhangliebiao"></i>
            <span slot="title">文章列表</span>
          </el-menu-item>
          <el-menu-item index="/workmanage" v-if="$comm.computer(386)">
            <i class="iconfont icon-wenzhangliebiao"></i>
            <span slot="title">事务性管理</span>
          </el-menu-item>
          <el-menu-item index="/agreement" v-if="$comm.computer(190)">
            <i class="iconfont icon-xieyiliebiao"></i>
            <span slot="title">协议列表</span>
          </el-menu-item>
          <el-menu-item index="/activityCenter" v-if="$comm.computer(250)">
            <i class="iconfont icon-xieyiliebiao"></i>
            <span slot="title">活动中心</span>
          </el-menu-item>
          <el-menu-item index="/newsList" v-if="$comm.computer(206)">
            <i class="iconfont icon-xinwenliebiao"></i>
            <span slot="title">实时新闻</span>
          </el-menu-item>
        </el-submenu>
        <!-- 积分乐园 -->
        <el-submenu index="/1108539" v-if="$comm.computer(375)">
          <template slot="title">
            <i class="el-icon-s-comment"></i>
            <span slot="title">积分乐园</span>
          </template>
          <el-menu-item index="/leaver" v-if="$comm.computer(427)">
            <i class="el-icon-medal"></i>
            <span slot="title">会员等级</span>
          </el-menu-item>
          <el-menu-item index="/qiandao" v-if="$comm.computer(376)">
            <i class="el-icon-s-claim"></i>
            <span slot="title">签到信息</span>
          </el-menu-item>
          <el-menu-item index="/integral" v-if="$comm.computer(377)">
            <i class="el-icon-goods"></i>
            <span slot="title">积分商城</span>
          </el-menu-item>
          <el-menu-item index="/integralclass" v-if="$comm.computer(416)">
            <i class="el-icon-goods"></i>
            <span slot="title">商城分类</span>
          </el-menu-item>
          <el-menu-item index="/shoporderlist" v-if="$comm.computer(417)">
            <i class="el-icon-goods"></i>
            <span slot="title">商品订单</span>
          </el-menu-item>
          <el-menu-item index="/youhuiquan" v-if="$comm.computer(384)">
            <i class="el-icon-present"></i>
            <span slot="title">卡券模版</span>
          </el-menu-item>
          <el-menu-item index="/youhuiquanhistory" v-if="$comm.computer(385)">
            <i class="el-icon-present"></i>
            <span slot="title">卡券记录</span>
          </el-menu-item>
        </el-submenu>
        <!-- 题库管理 -->
        <el-submenu index="/1108533" v-if="$comm.computer(378)">
          <template slot="title">
            <i class="el-icon-s-comment"></i>
            <span slot="title">题库管理</span>
          </template>
          <el-menu-item index="/questionList" v-if="$comm.computer(379)">
            <i class="el-icon-chat-dot-square"></i>
            <span slot="title">题目列表</span>
          </el-menu-item>
          <el-menu-item index="/questionitem" v-if="$comm.computer(380)">
            <i class="el-icon-s-help"></i>
            <span slot="title">风险等级</span>
          </el-menu-item>
        </el-submenu>
        <!-- 轮播图管理 -->
        <el-submenu index="/2-2-4" v-if="$comm.computer(188)">
          <template slot="title">
            <i class="iconfont icon-lunbotuguanli1"></i>
            <span>轮播图管理</span>
          </template>
          <el-menu-item index="/banner" v-if="$comm.computer(191)">
            <i class="iconfont icon-lunbotuguanli"></i>
            <span slot="title">轮播图列表</span>
          </el-menu-item>
          <el-menu-item index="/productbanner" v-if="$comm.computer(392)">
            <i class="iconfont icon-lunbotuguanli"></i>
            <span slot="title">产品轮播图列表</span>
          </el-menu-item>
        </el-submenu>
        <!-- 记录管理 -->
        <el-submenu index="/10010" v-if="$comm.computer(112)">
          <template slot="title">
            <i class="el-icon-notebook-1"></i>
            <span>记录管理</span>
          </template>
          <el-menu-item index="/audithistory" v-if="$comm.computer(406)">
            <i class="el-icon-notebook-2"></i>
            <span slot="title">审核记录</span>
          </el-menu-item>
          <el-menu-item index="/workerchange" v-if="$comm.computer(448)">
            <i class="el-icon-notebook-2"></i>
            <span slot="title">交接记录</span>
          </el-menu-item>
          <el-menu-item index="/records" v-if="$comm.computer(113)">
            <i class="el-icon-notebook-2"></i>
            <span slot="title">订单记录</span>
          </el-menu-item>
          <el-menu-item index="/Payment" v-if="$comm.computer(114)">
            <i class="el-icon-money"></i>
            <span slot="title">付款记录</span>
          </el-menu-item>
          <el-menu-item index="/System" v-if="$comm.computer(150)">
            <i class="el-icon-more"></i>
            <span slot="title">系统记录</span>
          </el-menu-item>
        </el-submenu>
        <!-- 微信通知 -->
        <el-submenu index="/wechart" v-if="$comm.computer(390)">
          <template slot="title">
            <i class="el-icon-bell"></i>
            <span>微信通知</span>
          </template>
          <el-menu-item index="/noticeset" v-if="$comm.computer(391)">
            <i class="el-icon-setting"></i>
            <span slot="title">通知配置</span>
          </el-menu-item>
          <el-menu-item index="/noticesetlist" v-if="$comm.computer(403)">
            <i class="el-icon-setting"></i>
            <span slot="title">通知记录</span>
          </el-menu-item>
        </el-submenu>
        <!-- 数据统计 -->
        <el-submenu index="/70151" v-if="$comm.computer(176)">
          <template slot="title">
            <i class="el-icon-s-data"></i>
            <span>数据统计</span>
          </template>

          <el-submenu index="1-4" v-if="$comm.computer(184)">
            <template slot="title">
              <i class="el-icon-monitor"></i>数据面板</template
            >
            <a
              :href="iframeSrc1"
              target="_blank"
              v-if="$comm.computer(349)"
              style="text-align: center;display: block;color: rgb(144, 147, 153);text-decoration: none;padding: 15px 0;"
            >
              <i class="el-icon-s-marketing"></i>面板1
            </a>
            <a
              :href="iframeSrc2"
              target="_blank"
              v-if="$comm.computer(353)"
              style="text-align: center;display: block;color: rgb(144, 147, 153);text-decoration: none;padding: 15px 0;"
            >
              <i class="el-icon-s-platform"></i>面板2
            </a>
            <!-- <el-menu-item index="/bigDataOne" v-if="$comm.computer(349)"
              >面板1</el-menu-item
            > -->
            <el-menu-item index="/yearcheck" v-if="$comm.computer(438)">
              <i class="el-icon-s-data"></i>年度考核
            </el-menu-item>
          </el-submenu>
          <!-- 异常数据 -->
          <el-submenu index="1-9" v-if="$comm.computer(184)">
            <!-- 396 -->
            <template slot="title">
              <i class="el-icon-s-data"></i>
              <span>异常数据</span>
            </template>
            <el-menu-item index="/errmoney" v-if="$comm.computer(388)">
              <i class="el-icon-s-open"></i>
              <span slot="title">异常打款</span>
            </el-menu-item>
            <el-menu-item index="/errorder" v-if="$comm.computer(387)">
              <i class="el-icon-s-release"></i>
              <span slot="title">异常订单</span>
            </el-menu-item>
            <el-menu-item index="/erruser" v-if="$comm.computer(394)">
              <i class="el-icon-s-custom"></i>
              <span slot="title">异常客户</span>
            </el-menu-item>
            <el-menu-item index="/errbanck" v-if="$comm.computer(395)">
              <i class="el-icon-s-finance"></i>
              <span slot="title">异常银行卡</span>
            </el-menu-item>
          </el-submenu>
          <el-menu-item index="/exports" v-if="$comm.computer(185)">
            <i class="el-icon-printer"></i>
            <span slot="title">数据导出</span>
          </el-menu-item>
          <el-menu-item index="/export" v-if="$comm.computer(186)">
            <i class="el-icon-printer"></i>
            <span slot="title">导出记录</span>
          </el-menu-item>
        </el-submenu>
        <!-- OA管理 -->
        <el-submenu index="/newproduct" v-if="$comm.computer(423)">
          <template slot="title">
            <i class="el-icon-s-unfold"></i>
            <span>OA管理</span>
          </template>
          <el-menu-item index="/newOAapply" v-if="$comm.computer(424)">
            <i class="el-icon-document-add"></i>
            <span slot="title">OA申请</span>
          </el-menu-item>
          <el-menu-item index="/newOAlist" v-if="$comm.computer(425)">
            <i class="el-icon-document-checked"></i>
            <span slot="title">OA审批</span>
          </el-menu-item>
          <el-menu-item index="/newOAtemplate" v-if="$comm.computer(426)">
            <i class="el-icon-wallet"></i>
            <span slot="title">OA模板管理</span>
          </el-menu-item>
        </el-submenu>
        <!-- OA管理废弃 -->
        <el-submenu index="/product" v-if="$comm.computer(158)">
          <template slot="title">
            <i class="el-icon-s-unfold"></i>
            <span>OA管理(已废弃)</span>
          </template>
          <el-menu-item index="/OAApply" v-if="$comm.computer(159)">
            <i class="el-icon-document-add"></i>
            <span slot="title">OA申请(旧)</span>
          </el-menu-item>
          <el-menu-item index="/OAAudit" v-if="$comm.computer(160)">
            <i class="el-icon-document-checked"></i>
            <span slot="title">OA审批(旧)</span>
          </el-menu-item>
          <el-menu-item index="/OAsubmission" v-if="$comm.computer(161)">
            <i class="el-icon-folder-checked"></i>
            <span slot="title">OA记录(旧)</span>
          </el-menu-item>
          <el-menu-item index="/OATemplate" v-if="$comm.computer(162)">
            <i class="el-icon-wallet"></i>
            <span slot="title">OA模板管理(旧)</span>
          </el-menu-item>
        </el-submenu>
        <!-- 系统管理 -->
        <el-submenu index="/10000" v-if="$comm.computer(21)">
          <template slot="title">
            <i class="el-icon-s-operation"></i>
            <span>系统管理</span>
          </template>
          <el-menu-item index="/bankManagement" v-if="$comm.computer(22)">
            <i class="el-icon-school"></i>
            <span slot="title">银行管理</span>
          </el-menu-item>
          <el-menu-item index="/branchRate" v-if="$comm.computer(341)">
            <i class="el-icon-pie-chart"></i>
            <span slot="title">金额占比</span>
          </el-menu-item>
          <el-submenu index="100000-9" v-if="$comm.computer(397)">
            <!-- 396 -->
            <template slot="title">
              <i class="el-icon-set-up"></i>
              <span>小工具</span>
            </template>
            <el-menu-item index="/shouyimoney" v-if="$comm.computer(399)">
              <i class="el-icon-s-shop"></i>
              <span slot="title">收益模拟</span>
            </el-menu-item>
            <el-menu-item index="/timeimitate" v-if="$comm.computer(398)">
              <i class="el-icon-time"></i>
              <span slot="title">时间模拟</span>
            </el-menu-item>
            <el-menu-item
              index="/changeremoneyhistry"
              v-if="$comm.computer(409)"
            >
              <i class="el-icon-time"></i>
              <span slot="title">更改收益记录</span>
            </el-menu-item>
            <el-menu-item index="/vacation" v-if="$comm.computer(389)">
              <i class="el-icon-date"></i>
              <span slot="title">节假日管理</span>
            </el-menu-item>
          </el-submenu>
          <el-menu-item index="/SystemSettings" v-if="$comm.computer(26)">
            <i class="el-icon-setting"></i>
            <span slot="title">系统设置</span>
          </el-menu-item>
          <el-menu-item index="/SubBranch" v-if="$comm.computer(339)">
            <i class="el-icon-office-building"></i>
            <span slot="title">支行管理</span>
          </el-menu-item>
          <a
            :href="
              'https://updates.1024xxkj.com/?token=' +
                token +
                '&proje_key=' +
                proje_key
            "
            target="_blank"
            v-if="$comm.computer(175)"
            style="text-align: center;display: block;color: rgb(144, 147, 153);text-decoration: none;padding: 15px 0;margin-left:-30px"
          >
            <i class="el-icon-setting"></i>数据导入
          </a>
        </el-submenu>
      </el-menu>
    </aside>
    <!-- 修改密码页面 -->
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      v-if="dialogVisible"
      style="padding:0 20px"
    >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="密码修改" name="first">
          <el-form
            label-width="80px"
            ref="password"
            :model="password"
            :rules="rules"
          >
            <el-form-item label="原密码" prop="Original">
              <el-input
                placeholder="请输入密码"
                v-model="password.Original"
                show-password
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="new">
              <el-input
                placeholder="请输入新密码"
                v-model="password.new"
                show-password
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item label="再次输入" prop="Confirm">
              <el-input
                placeholder="请再次输入新密码"
                v-model="password.Confirm"
                show-password
                clearable
              >
              </el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="验证码修改" name="second">
          <el-form
            label-width="80px"
            ref="code_modification"
            :model="code_modification"
            :rules="code_rules"
          >
            <el-form-item label="手机号码" prop="phoneNum">
              <el-input
                placeholder="请输入手机号码"
                v-model="code_modification.phoneNum"
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="password">
              <el-input
                placeholder="请输入新密码"
                v-model="code_modification.password"
                show-password
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
              <el-input
                placeholder="请输入验证码"
                v-model="code_modification.code"
                clearable
              >
                <el-button
                  slot="append"
                  :disabled="buttonCode"
                  style="background:#409eff;color:#fff;width:135px;height:38px;"
                  @click="buttonClick"
                  >{{ code
                  }}{{ code == '发送验证码' ? null : 's后重新获取' }}</el-button
                >
              </el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button @click="noneDialog">取 消</el-button>
        <el-button type="primary" @click="ChangePassword">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 顶部 -->
    <div class="main-container">
      <div class="header" style="height: 50px">
        <div class="navbar">
          <i
            :class="[
              'toggle-button',
              isCollapse ? 'el-icon-s-fold' : 'el-icon-s-unfold'
            ]"
            @click="toggleSidebar()"
          ></i>
          <!-- 刷新按钮 -->
          <el-button
            icon="el-icon-refresh-right"
            circle
            @click="Refresh"
            style="position: absolute;
            left:40px;
            border: none;
            font-size: 20px;"
          >
          </el-button>
          <!-- 右上角姓名按钮 -->
          <el-popover placement="bottom" width="60" trigger="hover">
            <span
              class="xiugai"
              @click="handleLogouting()"
              style="font-size: 12px; cursor: pointer; color: #fff; "
              >修改密码</span
            >
            <span
              class="spanClass"
              @click="handleLogout()"
              style="font-size: 12px; cursor: pointer; color: #fff; "
              >退出</span
            >
            <el-button slot="reference" style="color:#409EFF">{{
              namse
            }}</el-button>
          </el-popover>
        </div>
        <div class="tags-view-container">
          <el-tabs
            v-model="editableTabsValue"
            type="card"
            :closable="menuTabs.length > 1"
            style="background:#fff"
            @tab-remove="removeTab"
            @tab-click="tabClick"
          >
            <el-tab-pane
              v-for="item in menuTabs"
              :key="item.name"
              :label="item.title"
              :name="item.name"
            >
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="app-main">
        <keep-alive>
          <router-view />
        </keep-alive>
        <!-- <div class="bac-name">
          <el-row
            type="flex"
            class="row-bg"
            justify="space-around"
            v-for="(item, index) in 4"
            :key="index"
          >
            <el-col :span="4"
              ><div class="grid-content bg-purple">{{ namse }}</div></el-col
            >
            <el-col :span="4"
              ><div class="grid-content bg-purple">{{ namse }}</div></el-col
            >
            <el-col :span="4"
              ><div class="grid-content bg-purple">{{ namse }}</div></el-col
            >
            <el-col :span="4"
              ><div class="grid-content bg-purple">{{ namse }}</div></el-col
            >
            <el-col :span="4"
              ><div class="grid-content bg-purple">{{ namse }}</div></el-col
            >
            <el-col :span="4"
              ><div class="grid-content bg-purple">{{ namse }}</div></el-col
            >
          </el-row>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { validatePhoneTwo } from '@/api/verification'
import {
  ReturnList,
  SendCode,
  changePassword,
  queryOrderNum
} from '@/api/login'

export default {
  inject: ['reload'], //注入方法
  data() {
    return {
      proje_key: process.env.VUE_APP_URL,
      isCollapse: false,
      currentPath: [],
      num: false,
      dialogVisible: false,
      input: '',
      shows: true,
      counts: '',
      password: {
        Original: '',
        Confirm: '',
        new: '',
        phoneNum: '',
        code: ''
      },
      // 验证码点击
      buttonCode: false,
      // 验证码
      code: '发送验证码',
      code_modification: {
        phoneNum: '',
        code: '',
        type: 2,
        password: ''
      },
      rules: {
        Original: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!this.password.Original) {
                callback(new Error('请输入密码'))
              } else if (value.length < 6) {
                callback(new Error('密码不能小于六位'))
              } else {
                callback()
              }
            },
            trigger: 'change'
          }
        ],
        new: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!this.password.new) {
                callback(new Error('请输入密码'))
              } else if (value.length < 6) {
                callback(new Error('密码不能小于六位'))
              } else {
                callback()
              }
            },
            trigger: 'change'
          }
        ],
        Confirm: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!this.password.Confirm) {
                callback(new Error('请输入密码'))
              } else if (value != this.password.new) {
                callback(new Error('两次密码不一致'))
              } else if (value.length < 6) {
                callback(new Error('密码不能小于六位'))
              } else {
                callback()
              }
            },
            trigger: 'change'
          }
        ]
      },
      code_rules: {
        phoneNum: [
          { required: true, message: '请填写正确的手机号', trigger: 'change' },
          {
            validator: validatePhoneTwo,
            trigger: 'change'
          }
        ],
        code: [
          { required: true, message: '验证码不能为空', trigger: 'change' }
        ],
        password: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!this.code_modification.password) {
                callback(new Error('请输入密码'))
              } else if (value.length < 6) {
                callback(new Error('密码不能小于六位'))
              } else {
                callback()
              }
            },
            trigger: 'change'
          }
        ]
      },
      // path_ing:'index'
      // 跳转的token
      token: '',
      back: '',
      // 系统
      system: '',
      ing: null,
      timer_review: null,
      timer_Personal: null,
      timer_Reject: null,
      timering: null,
      timer_records: null,
      // 初审
      examination: '',
      // 复审
      review: '',
      // 个人
      personal: '',
      // 团队
      team: '',
      // OA
      oa_records: '',
      // 判断是否点击
      judeg: 1,
      // 点击后的值
      value: '',
      notifications: {},
      // 默认打开的值
      activeName: 'first',
      // 延时器
      setTimeoutIng: '',
      // 高亮的路由
      defauitUrl: 'index',
      // 标签页数据
      menuTabs: [
        {
          name: '/index',
          title: '首页'
        }
      ],
      // 默认打开
      editableTabsValue: '/index',
      // 延时器
      Timeout: '',
      namse: ''
    }
  },
  // watch: {
  //   $route(to, from) {
  //     console.log(to, from)
  //   }
  // },
  computed: {
    iframeSrc1() {
      const url = {
        'hx-shang': 'https://data.1024xxkj.com?proje_key=hx',
        'jc-shang': 'https://data.1024xxkj.com?proje_key=jc',
        'gy-shang': 'https://data.1024xxkj.com?proje_key=gbss',
        'jc-xia': 'https://data.1024xxkj.com?proje_key=cs',
        'zq-xia': 'https://data.1024xxkj.com?proje_key=zq',
        'local-host': 'https://data.1024xxkj.com?proje_key=cs',
        'jn-fs': 'https://data.1024xxkj.com?proje_key=jnfs',
        'tc-shang': 'https://data.1024xxkj.com?proje_key=tcty',
        'jc-zyxx': 'https://data.1024xxkj.com?proje_key=zyxx'
      }[this.proje_key]
      return url + '&embed=false&token=' + localStorage.getItem('token')
    },
    iframeSrc2() {
      const url = {
        'hx-shang': 'https://data.1024xxkj.com/two?proje_key=hx',
        'jc-shang': 'https://data.1024xxkj.com/two?proje_key=jc',
        'gy-shang': 'https://data.1024xxkj.com/two?proje_key=gbss',
        'jc-xia': 'https://data.1024xxkj.com/two?proje_key=cs',
        'zq-xia': 'https://data.1024xxkj.com/two?proje_key=zq',
        'local-host': 'https://data.1024xxkj.com/two?proje_key=cs',
        'jn-fs': 'https://data.1024xxkj.com?proje_key=jnfs',
        'tc-shang': 'https://data.1024xxkj.com?proje_key=tcty',
        'jc-zyxx': 'https://data.1024xxkj.com?proje_key=zyxx'
      }[this.proje_key]
      return url + '&embed=false&token=' + localStorage.getItem('token')
    }
  },
  beforeDestroy() {
    setTimeout(() => {
      clearInterval(this.timering)
      this.timering = ''
      this.closeAllNotification()
      clearTimeout(this.setTimeoutIng)
      this.setTimeoutIng = ''
    }, 1500)
  },
  mounted() {
    this.defauitUrl = window.location.href.split('/#')[1]
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.addEventListener('unload', e => this.unloadHandler(e))
  },
  destroyed() {
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.removeEventListener('unload', e => this.unloadHandler(e))
  },
  created() {
    // console.log(localStorage.getItem('name'))
    this.namse = localStorage.getItem('name')
    // 标签
    this.editableTabsValue = this.$route.path
    if (this.$route.path == '/index') {
      //
    } else {
      this.menuTabs.push({
        name: this.$route.path,
        title: this.$route.name
      })
    }
    // 权限列表
    this.returntion()
    // 当前网址
    this.back = process.env.VUE_APP_URL
    if (this.back == 'jc-shang') {
      this.system = '锦程Crm管理系统'
    } else if (this.back == 'hx-shang') {
      this.system = '华鑫Crm管理系统'
    } else if (this.back == 'zq-xia') {
      this.system = '丰石客户CRM系统'
    } else if (this.back == 'jc-xia') {
      this.system = '本地测试CRM系统'
    } else if (this.back == 'local-host') {
      this.system = '电脑测试CRM系统'
    } else if (this.back == 'gy-shang') {
      this.system = '国邦盛世'
    } else if (this.back == 'jn-fs') {
      this.system = '丰晟CRM系统'
    } else if (this.back == 'tc-shang') {
      this.system = '天成投研CRM系统'
    } else if (this.back == 'jc-zyxx') {
      // this.system = '中炎信息CRM系统'
      this.system = '信息CRM系统'
    }
    // 获取token
    this.token = localStorage.getItem('token')
    // 清除定时弹窗
    clearInterval(this.timering)
    this.timering = ''
    this.closeAllNotification()
    this.timerFuction()
    this.timering = setInterval(() => {
      this.timerFuction()
    }, 1800000)
    this.setTimeoutIng = setTimeout(() => {
      location.reload()
    }, this.$comm.timeIng())
    let dialoag = localStorage.getItem('account_status')
    if (dialoag == 1) {
      this.dialogVisible = true
    } else {
      this.dialogVisible = false
    }
  },
  // computed: {
  //   // 我们使用计算属性来获取到当前点击的菜单的路由路径，然后设置default-active中的值
  //   // 使得菜单在载入时就能对应高亮
  //   activeMenu() {
  //     const route = this.$route
  //     const { meta, path } = route
  //     // if set path, the sidebar will highlight the path you set
  //     // 可以在路由配置文件中设置自定义的路由路径到meta.activeMenu属性中，来控制菜单自定义高亮显示
  //     if (meta.activeMenu) {
  //       return meta.activeMenu
  //     }
  //     return path
  //   }
  // },
  methods: {
    // 导航菜单选择事件
    menuSelected(index, indexPath) {
      let key = this.$router.options.routes[0].children.find(
        i => '/' + i.path == index
      )
      this.editableTabsValue = '/' + key.path
      this.defauitUrl = '/' + key.path
      if (
        this.menuTabs.find(i => i.name == index) == undefined &&
        this.menuTabs
          .map(i => {
            return i.name == index ? i.name : true
          })
          .indexOf(index) == -1
      ) {
        if (this.menuTabs.length >= 9) {
          this.menuTabs.splice(1, 1)
          this.menuTabs.push({
            title: key.name,
            name: '/' + key.path
          })
        } else {
          this.menuTabs.push({
            title: key.name,
            name: '/' + key.path
          })
        }
      }
    },
    // 删除标签页
    removeTab(row) {
      this.menuTabs = this.menuTabs.filter(item => item.name !== row)
      const path = this.menuTabs[this.menuTabs.length - 1].name
      this.editableTabsValue = path
      this.defauitUrl = path
      if (row == this.$route.path) {
        this.$router.push(path)
      }
    },
    // 跳转标签页
    tabClick(row) {
      //   console.log(row)
      this.$router.push(row._props.name)
      this.defauitUrl = row._props.name
    },
    // tab切换方法
    handleClick(val) {
      if (val.name == 'second') {
        this.judeg = 2
        this.$refs.code_modification.resetFields()
      } else {
        this.judeg = 1
        this.$refs.password.resetFields()
      }
    },
    // 导航菜单栏收缩
    toggleSidebar() {
      this.isCollapse = !this.isCollapse
    },
    // 刷新页面
    Refresh() {
      this.reload()
      // this.tabClick()
      // const { fullPath } = this.$route
      // this.$router.replace({
      //   path: '/refresh' + fullPath
      // })
    },
    // 点击验证码的方法
    buttonClick() {
      this.$refs.code_modification.validateField('phoneNum', valid => {
        if (valid == '') {
          this.code = 60
          this.buttonCode = true
          SendCode({ phone_num: this.code_modification.phoneNum })
          let timering = setInterval(() => {
            this.code--
            if (this.code <= 0) {
              this.code = '发送验证码'
              this.buttonCode = false
              clearInterval(timering)
            }
          }, 1000)
        }
      })
    },
    // 定时器方法
    timerFuction() {
      this.closeAllNotification()
      let val = localStorage.getItem('userId') + localStorage.getItem('name')
      queryOrderNum().then(res => {
        if (res.data.code == 200) {
          this.examination = res.data.data.audit_one
          this.review = res.data.data.audit_two
          this.team = res.data.data.change_all
          this.personal = res.data.data.change_self
          this.oa_records = res.data.data.oa_records
          // 订单初审
          if (
            this.examination != 0 &&
            this.$comm.getCookie('订单初审' + val) == null
          ) {
            // this.$comm.setCookie('订单初审' + val, 'implement', 12)
            this.ing = setTimeout(() => {
              this.computed('订单初审', this.examination, '/OrderAuditOne')
            }, 500)
          }
          // 常规复审
          if (
            this.review != 0 &&
            this.$comm.getCookie('常规复审' + val) == null
          ) {
            this.timer_review = setTimeout(() => {
              this.computed('常规复审', this.review, '/OrderAuditTwo')
            }, 600)
          }
          // 团队订单/个人
          if (
            this.personal != 0 &&
            this.$comm.getCookie('待修改订单/个人' + val) == null
          ) {
            this.timer_Personal = setTimeout(() => {
              this.computed('待修改订单/个人', this.personal, '/PersonalOrder')
            }, 700)
          }
          // 团队订单/团队
          if (
            this.team != 0 &&
            this.$comm.getCookie('待修改订单/团队' + val) == null
          ) {
            this.timer_Reject = setTimeout(() => {
              this.computed('待修改订单/团队', this.team, '/Rejectorder')
            }, 800)
          }
          // OA
          if (
            this.oa_records != 0 &&
            this.$comm.getCookie('OA客户审批' + val) == null
          ) {
            this.timer_records = setTimeout(() => {
              this.computed('OA客户审批', this.oa_records, '/OAAudit')
            }, 800)
          }
        } else {
          this.closeAllNotification()
          clearInterval(this.timering)
          this.timering = ''
        }
      })
    },
    computed(titles, value, path) {
      this.timeOpen(titles, value, path)
    },
    //关闭单个通知
    closeNotification(id) {
      this.notifications[id].close()
      delete this.notifications[id]
    },
    // 关闭所有通知
    closeAllNotification() {
      for (let key in this.notifications) {
        this.notifications[key].close()
        delete this.notifications[key]
      }
    },
    // 打开一个新通知
    timeOpen(titles, value, path) {
      // const genPath = function(path) {
      //   return () => {
      //     this.$router.push(path)
      //   }
      // }
      const genPath = path => () => {
        this.defauitUrl = path
        if (!this.menuTabs.find(i => i.name == path)) {
          this.menuTabs.push({
            title: titles,
            name: path
          })
          this.editableTabsValue = path
        } else {
          this.editableTabsValue = path
        }
        return this.$router.push(path)
      }
      const changeBox = titles => () => this.checkBox(titles)
      let key = this.$route.path
      if (value != 0 && key != path) {
        const h = this.$createElement
        let notify = this.$notify({
          title: titles,
          dangerouslyUseHTMLString: true,
          duration: 0,
          message: h('div', [
            h(
              'p',
              {
                style: {
                  float: 'left'
                }
              },
              '你有'
            ),
            h(
              'p',
              {
                style: {
                  float: 'left',
                  color: '#67c23a'
                }
              },
              value
            ),
            h(
              'p',
              {
                style: {
                  float: 'left'
                }
              },
              '条消息需要处理'
            ),
            h(
              'el-link',
              {
                style: {
                  color: '#67c23a',
                  margin: '-3px 0 0 15px'
                },
                on: {
                  click: genPath(path)
                }
              },
              '点击去查看'
            ),
            h(
              'el-checkbox',
              {
                on: {
                  change: changeBox(titles)
                }
              },
              '今日不再提示'
            )
          ]),
          type: 'info'
        })
        this.notifications[titles] = notify
      }
    },
    // checkBox 选择弹窗事件
    checkBox(titles) {
      var x = new Date()
      x.setHours(0, 0, 0, 0)
      var y = new Date()
      let key =
        (24 * 3600 * 1000 - (y.getTime() - x.getTime())) / 1000 / (60 * 60)
      let times = key.toString().slice(0, 3)
      let val = localStorage.getItem('userId') + localStorage.getItem('name')
      this.$comm.setCookie(titles + val, 'implement', times)
      this.closeNotification(titles)
      if (
        (this.examination == 0 ||
          this.$comm.getCookie('订单初审' + val) != null) &&
        (this.review == 0 || this.$comm.getCookie('常规复审' + val) != null) &&
        (this.personal == 0 ||
          this.$comm.getCookie('待修改订单/个人' + val) != null) &&
        (this.team == 0 ||
          this.$comm.getCookie('待修改订单/团队' + val) != null) &&
        (this.oa_records == 0 ||
          this.$comm.getCookie('OA客户审批' + val) != null)
      ) {
        clearInterval(this.timering)
        this.timering = ''
      }
    },
    beforeunloadHandler() {
      this._beforeUnload_time = new Date().getTime()
    },
    unloadHandler(e) {
      this._gap_time = new Date().getTime() - this._beforeUnload_time
      debugger
      //判断是窗口关闭还是刷新
      if (this._gap_time <= 5) {
        //如果是登录状态，关闭窗口前，移除用户
        if (!this.showLoginButton) {
          window.localStorage.clear()
        }
      }
    },
    // 对话框事件
    handleClose() {
      this.$confirm('确认关闭？')
        .then(_ => {
          if (localStorage.getItem('account_status') != 1) {
            this.dialogVisible = false
          } else {
            this.$message({
              type: 'warning',
              message: '请修改密码'
            })
          }
        })
        .catch(_ => {})
    },
    // 返回用户的权限列表
    returntion() {
      ReturnList().then(res => {
        // vuex存放值
        this.$store.state.jurisdiction = res.data.data
      })
    },

    handleLogout() {
      this.$confirm('是否确认退出？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // window.localStorage.clear()
          localStorage.removeItem('token')
          this.$router.push('/Login')
          this.$nectTick(() => {
            this.closeAllNotification()
            clearInterval(this.timering)
            this.timering = ''
          })
          this.$message({
            type: 'success',
            message: '退出成功!'
          })
        })
        .catch(() => {})
    },
    // 修改密码按钮
    handleLogouting() {
      this.dialogVisible = true
      for (let key in this.password) {
        this.password[key] = ''
      }
    },
    // 修改密码确定事件
    ChangePassword() {
      if (this.judeg == 1) {
        this.$refs.password.validate(valid => {
          if (valid) {
            let pass = {
              type: 1,
              old_password: this.password.Original,
              password: this.password.Confirm
            }
            changePassword(pass).then(res => {
              if (res.data.code == 200) {
                this.$message({
                  type: 'success',
                  message: res.data.msg
                })
                this.dialogVisible = false
                // window.localStorage.clear('token')
                clearInterval(this.timering)
                this.timering = ''
                this.closeAllNotification()
                localStorage.removeItem('token')
                this.$router.push('/Login')
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg
                })
              }
            })
          }
        })
      } else {
        this.$refs.code_modification.validate(valid => {
          if (valid) {
            changePassword(this.code_modification).then(res => {
              if (res.data.code == 200) {
                this.$message({
                  type: 'success',
                  message: res.data.msg
                })
                this.dialogVisible = false
                // window.localStorage.clear('token')
                clearInterval(this.timering)
                this.timering = ''
                this.closeAllNotification()
                localStorage.removeItem('token')
                this.$router.push('/Login')
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg
                })
              }
            })
          }
        })
      }
    },
    // 取消事件
    noneDialog() {
      if (localStorage.getItem('account_status') != 1) {
        this.dialogVisible = false
      } else {
        this.$message({
          type: 'warning',
          message: '请修改密码'
        })
      }
    }
  }
}
</script>

<style lang="less">
.el-divider--horizontal {
  margin: 10px 0 !important;
}
.el-popover {
  min-width: 0 !important;
}
.spanClass {
  width: 60px;
  height: 28px;
  background-color: #409eff;
  display: block;
  border-radius: 2px;
  line-height: 28px;
  text-align: center;
  margin-top: 10px;
}
.spanClass:hover {
  opacity: 0.8;
}
.xiugai {
  width: 60px;
  height: 28px;
  background-color: #409eff;
  display: block;
  border-radius: 2px;
  line-height: 28px;
  text-align: center;
}
.xiugai:hover {
  opacity: 0.8;
}
.app-wrapper {
  height: 100%;

  .el-menu-item-group__title {
    display: none;
  }
  .el-tabs__header {
    margin: 0 0 14px;
  }
  &.mini-side {
    .sidebar {
      width: 56px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .logo-container {
        justify-content: center;
        > img {
          margin-right: 0px;
        }
        > span {
          display: none;
        }
      }
    }
    .main-container {
      width: calc(100% - 56px);
      margin-left: 56px;
      .header {
        .navbar {
          width: calc(100% - 76px);
        }
      }
    }
  }

  .sidebar {
    width: 200px;
    height: 100%;
    position: fixed;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #303133;

    .logo-container {
      width: 100%;
      height: 50px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: #000000;

      > img {
        width: 32px;
        height: 32px;
        margin-right: 10px;
      }

      > span {
        color: #ffffff;
        font-size: 17px;
        font-weight: 400;
      }
    }

    .el-menu {
      border-right: none;
    }
  }

  /*右侧工作区*/

  .main-container {
    width: calc(100% - 200px);
    height: 100%;
    margin-left: 200px;

    .header {
      width: 100%;
      height: 85px;
      position: fixed;
      z-index: 98;
      .navbar {
        width: calc(100% - 220px);
        height: 47px;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px 10px;
        background-color: #ffffff;
        box-shadow: 0px 1px 4px rgba(0, 21, 41, 0.12);

        .toggle-button {
          font-size: 20px;
        }
      }
    }
    .app-main {
      min-height: 85px;
      padding: 102px 10px 10px 10px !important;
      .bac-name {
        position: absolute;
        font-size: 18px;
        width: 100%;
        top: 0;
        line-height: 80px;
        overflow: hidden;
        transform: rotate(-30deg);
        left: 10%;
        z-index: -999;
        color: rgb(244, 216, 149, 0.2);
        .row-bg {
          margin-bottom: 60px;
          // background-color: #f9fafc;
        }
      }
    }
  }
}
</style>
