/**
 * @Author: 李明
 * @Date: 2021-11-05 11:07:12
 * @LastEditTime: 2022-12-20 13:41:33
 * @LastEditors: 李明
 * @Description:
 * @FilePath: /CRM2.0-VUE/src/filters/index.js
 * @@山东壹零贰肆信息科技有限公司
 */

import { isNull, isNumber } from 'lodash'

export function idType(value) {
  // -1未知 1身份证 2港澳通行证
  let obj = {
    1: '身份证',
    2: '港澳通行证',
    3: '营业执照'
  }[value]
  return obj ?? '未知'
}
export function OAidType(value) {
  // -1未知 1身份证 2港澳通行证
  let obj = {
    1: '身份证',
    2: '港澳通行证',
    3: '营业执照'
  }[value]
  return obj
}
export function judegTime(value) {
  // -1未知 1身份证 2港澳通行证
  let obj = {
    订单初审: this.examination,
    常规复审: this.review,
    '待修改订单(个人)': this.personal,
    '待修改订单(团队)': this.team
  }[value]
  return obj
}
export function yearType(value) {
  let obj = {
    1: '年化比例',
    2: '固定年化',
    3: '自动计算'
  }[value]
  return obj ?? '未知'
}
export function filDatetion(value) {
  let obj = {
    0: '-',
    1: '初审',
    2: '复审'
  }[value]
  return obj ?? '未知'
}
export function typeIng(value) {
  let obj = {
    1: '订单记录',
    2: '打款记录',
    3: '客户信息',
    6: '台账导出'
  }[value]
  return obj ?? '未知'
}
export function payStatus(value) {
  let obj = {
    1: '待付款',
    2: '付款中',
    3: '已付款'
  }[value]
  return obj ?? '未知'
}
export function success(value) {
  let obj = {
    0: '-',
    1: '通过',
    2: '拒绝'
  }[value]
  return obj ?? '未知'
}
export function imageType(value) {
  // -1未知 1身份证正2身份证反3转款凭证4合同照片
  let obj = {
    '-1': '未知',
    1: '身份证正面',
    2: '身份证反面',
    3: '付款凭证',
    4: '合同照片',
    5: '银行卡正面',
    6: '银行卡反面',
    7: '其他'
  }[value]
  return obj ?? '未知'
}
export function recordType(value) {
  let obj = {
    1: '审核',
    2: '订单生成 ',
    3: '订单修改',
    4: '订单转移',
    5: '财务',
    6: '出纳'
  }[value]
  return obj ?? '未知'
}
export function sex(value) {
  let obj = {
    1: '男',
    2: '女'
  }[value]
  return obj ?? '未知'
}
export function filterstatue(value) {
  let obj = {
    1: '审核人',
    2: '抄送人',
    3: '办理人',
    5: '发起人'
  }[value]
  return obj ?? '未知'
}
export function filDate(value) {
  let obj = {
    0: '待审核',
    1: '通过',
    2: '拒绝',
    3: '无需审核',
    4: '未开始'
  }[value]
  return obj ?? '未知'
}
export function filDateing(value) {
  let obj = {
    0: '待审核',
    1: '通过',
    2: '拒绝',
    3: '无需审核',
    4: '未开始'
  }[value]
  return obj
}
export function orderStatus(value) {
  let obj = {
    '-1': '草稿',
    1: '待付款',
    2: '审核中',
    3: '待生效',
    4: '已生效',
    5: '拒绝',
    6: '已结束',
    7: '废弃',
    8: '提前终止'
  }[value]
  return obj ?? '未知'
}
export function oaType(value) {
  let obj = {
    1: '财务审批'
  }[value]
  return obj ?? '未知'
}
// 短信发送记录
export function SMSrecord(value) {
  let obj = {
    待发送: 1,
    发送中: 2,
    成功: 3,
    失败: 4,
    预约: 5
  }[value]
  return obj ?? '未知'
}
export function payType(value) {
  // 付款方式  2自然月  4到期 5不付款 4自定义
  let obj = {
    2: '周期付款',
    4: '到期本息',
    5: '不付款'
  }[value]
  return obj ?? '未填写'
}

export function investmentWay(value) {
  // 投资方式 1银行转账  2pos刷卡, 3转存,  4.POS刷卡/（银行转账）
  let obj = {
    1: '银行转账',
    2: 'pos刷卡',
    3: '转存',
    4: '通联支付'
  }[value]
  return obj ?? '未知'
}
// 万元
export function moneyDouble(value) {
  if (value) {
    if (process.env.VUE_APP_URL == 'jc-zyxx') {
      return value + '元'
    } else {
      if (value >= 10000) {
        return value / 10000 + '万元'
      } else if (value < 10000 && value >= 0) {
        return value + '元'
      }
    }
  }
  return '未填写'
}
// 万元
export function moneyDoubleTofixd(value) {
  if (value) {
    if (process.env.VUE_APP_URL == 'jc-zyxx') {
      return value.toFixed(2) + '元'
    } else {
      if (value >= 10000) {
        return (value / 10000).toFixed(2) + '万元'
      } else if (value < 10000 && value >= 0) {
        return value.toFixed(2) + '元'
      }
    }
  }
  return ''
}
export function fiternum(value) {
  // 投资方式 1银行转账  2pos刷卡, 3转存,  4.POS刷卡/（银行转账）
  let obj = {
    0: '待审核',
    1: '通过',
    2: '拒绝',
    3: '无需审核',
    4: '待启用'
  }[value]
  return obj
}
export function fitis_finally(value) {
  // 投资方式 1银行转账  2pos刷卡, 3转存,  4.POS刷卡/（银行转账）
  let obj = {
    1: '审核完成',
    2: '待审核',
    3: '审核终止（拒绝）',
    4: '草稿',
    5: '已撤销'
  }[value]
  return obj
}
// tab切换
export function Tabtion(value) {
  // 投资方式 1银行转账  2pos刷卡, 3转存,  4.POS刷卡/（银行转账）
  let obj = {
    已处理: '2',
    已发起: '3',
    我收到: '4'
  }[value]
  return obj
}
// tab ref对比
export function TabRecord(value) {
  // 投资方式 1银行转账  2pos刷卡, 3转存,  4.POS刷卡/（银行转账）
  let obj = {
    2: 'Record_Processed',
    3: 'Record_Initiated',
    4: 'Record_got'
  }[value]
  return obj
}
// 树状图拖拽
export function Tree(value) {
  // 投资方式 1银行转账  2pos刷卡, 3转存,  4.POS刷卡/（银行转账）
  let obj = {
    before: 1,
    inner: 3,
    after: 2
  }[value]
  return obj
}
// 轮播图跳转类型
export function jumpType(value) {
  let obj = {
    1: '不跳转',
    2: '产品',
    3: '文章',
    4: '链接'
  }[value]
  return obj
}
// 产品标签转字符串
export function productLabeling(value) {
  let obj = []
  value.map(i => {
    return obj.push(
      {
        1: '精选产品',
        2: '稳健'
      }[i]
    )
  })
  return obj == [] ? '' : obj.join(',')
}
// 产品标签转数字
export function productLabelingNumber(value) {
  let obj = []
  value.map(i => {
    return obj.push(
      {
        精选产品: 1,
        稳健: 2
      }[i]
    )
  })
  return obj == [] ? '' : obj
}
// 短信模板类型
export function TemplateType(value) {
  let obj = {
    1: '常规模板',
    2: '订单模板',
    3: '收益模板',
    4: '员工模板',
    5: '营销模板'
  }[value]
  return obj
}
// 银行卡状态
export function BankCardStatus(value) {
  let obj = {
    1: '常规',
    2: '通联认证中',
    3: '通联已认证'
  }[value]
  return obj
}
// 认证状态
export function Authentication(value) {
  let obj = {
    0: '未认证',
    null: '未认证',
    2: '已认证',
    3: '已提交',
    4: '认证失败'
  }[value]
  return obj
}
// 短信发送状态
export function SMSstatus(value) {
  let obj = {
    1: '待发送',
    2: '发送中',
    3: '发送成功',
    4: '发送失败',
    5: '预约'
  }[value]
  return obj
}
// 电子签约状态
export function signingStatus(value) {
  let obj = {
    1: '已签署',
    2: '未签署',
    3: '待签署',
    4: '已填充模板',
    5: '签署失败',
    6: '签署中'
  }[value]
  return obj
}
// 是否归档
export function Archive(value) {
  let obj = {
    1: '已归档',
    2: '未归档'
  }[value]
  return obj
}

// 状态
export function enterprise_status(value) {
  let obj = {
    0: '未认证',
    1: '管理员资料已提交',
    2: '企业基本资料已提交',
    3: '已提交待审核',
    4: '审核通过(认证完成)',
    5: '审核不通过',
    6: '人工初审通过'
  }[value]
  return obj
}
// 支付状态
export function paymentstatus(value) {
  let obj = {
    1: '交易成功',
    2: '未支付',
    3: '交易失败'
  }[value]
  return obj
}
// 协议标签
export function agreement(value) {
  let obj = {
    4: '帮助中心',
    5: '注册协议',
    6: '登录协议',
    7: '使用协议'
  }[value]
  return obj
}
// 协议标签
export function fileName(value) {
  let obj = {
    0: '交易对账文件',
    13: '余额对账文件',
    14: '资金对账单'
  }[value]
  return obj
}
// 员工状态
export function userStatus(value) {
  let obj = {
    0: '离职',
    1: '入职',
    2: '待入职'
  }[value]
  return obj
}
// 员工状态
export function is_positive(value) {
  let obj = {
    1: '已转正',
    2: '未转正'
  }[value]
  return obj ?? '未知'
}
// 产品周期单位
export function dayType(value) {
  let obj = {
    1: '日',
    2: '月',
    3: '年'
  }[value]
  return obj ?? '未知'
}
// 跟进类型
export function followType(value) {
  let obj = {
    1: '打电话',
    2: '发短信',
    3: '发微信',
    4: '线下拜访',
    5: '领取客户',
    6: '放入公海'
  }[value]
  return obj ?? '未知'
}
// 发行方机构类型
export function componentType(value) {
  let obj = {
    1: '发行方',
    2: '销售方',
    3: '担保方',
    4: '其他'
  }[value]
  return obj ?? '未知'
}

// 保留两位小数
export function fixedtwo(value) {
  return `${value.toFixed(2)}`
}

// 微信通知订单状态
export function typenoticeset(value) {
  let obj = {
    1: '审核',
    2: '订单生成',
    3: '订单修改',
    4: '订单转移'
  }[value]
  return obj ?? '未知'
}

// 业绩设置列表  bumen
export function performanclistsubset(value) {
  let obj = {
    department: '部门',
    levels: '职级',
    user: '个人'
  }[value]
  return obj ?? '未知'
}

// 业绩设置列表  zhibaio
export function performanclistymwdset(value) {
  let obj = {
    daily: '日业绩',
    weekly: '周业绩',
    monthly: '月业绩',
    yearly: '年业绩',
    default: '默认业绩'
  }[value]
  return obj ?? '未知'
}
